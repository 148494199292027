* {
  padding: 0;
  marging: 0
}

button {
  outline: 0 !important;
}

h1 {
  padding: 10px;
  background: #000;
  color: #fff;
  text-align: center;
}

.link {
  color: #fff;
  text-decoration: none;
  padding: 2px 10px;
}

.link.active {
  color: #d8a9b8;
  background: #fff;
  border-radius: 10px;
}

.link:hover, .link:focus{
  text-decoration: none;
  color: #583232;
}

.clear {
  clear: both;
}

.row {
  display: block !important;
  width: 100%;
}

.MuiInputBase-formControl {
  width: 100%;
}

.menu {
  margin-left: 30px;
}


.logo {
  background: #fff;
  width: 200px;
  margin-left: -48px;
  padding: 6px 6px 6px 21px;
}

.all-container {
  padding-top: 70px;
}

.management {
  position: absolute;
  bottom: 5px;
  left: 74px;
  color: #bbb;
  font-size: 15px;
  font-style: italic;
}

.spacer {
  margin-bottom: 10px;
  display: block;
}

.form-title {
  float: left;
}

/* Colors */
.gray-color {
  color: #bbb
}

.dgray-color {
  color: #495057;
}

.bg-pink {
  background-color: #d8a9b8;
}

.orange {
  color: #d8a9b8;
}

.links {
  color: #9b9c6f;
}

.links:hover {
  color: #9b9c6f;
  text-decoration: underline;
}

.primary-btn {
  background-color: #FFA500;
}


.loading-center {
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  background: url(https://drglowgetter.com/wp-content/uploads/2019/08/loading-gg.gif) no-repeat center center;
  height: 100px;
  opacity: 0.4;
}

/* events */

.rbc-event{
  border: none !important;
  background-color: #b3b0a4;
}

.rbc-event.rbc-selected {
  border: none !important;
  background-color: #B37B74 !important;
}

.rbc-today {
  background-color: #e6f9f1 !important;
}

.icon-list {
  position: relative;
  top: 4px;
}

.icon-top7 {
  position: relative;
  top: 7px;
}

.product-list {
  padding: 0 0 15px 15px;
  border-radius: 6px;
  background: #f9f2f2;
}

.emptyPie {
  text-align: center;
  padding: 100px 0;
}

.numbers-switch {
  margin: -50px 0 0 158px !important;
}

.location-sl {
  position: relative;
  left: 105px;
}

.not-owner {
  left: 19px
}

.location-sl .MuiInputBase-root, .location-sl .MuiSelect-icon{
  color: #fff;
}

.avatar {
  vertical-align: middle;
  border-radius: 50%;
}

.client-title{
  font-size: 27px;
  display: block;
}

.client-top {
  text-align: center;
}

.tab-menu {
  font-size: 11px;
  padding: 3px 7px 4px;
  border-radius: 7px;
  margin: 0 3px;
  cursor: pointer;
}

.tab-menu.active{
  background: #af8a8a;
  color: #fff;
}

.notif-paper {
  background: #fff;
  position: absolute;
  right: 19px;
  box-shadow: 0px 3px 6px #d0c4c4;
  padding: 0 5px;
}

.notif-btn {
  cursor: pointer;
}

.notif-item {
  padding: 6px 10px;
  display: block;
  color: #484141;
  border-top: 1px solid #ddd;
  min-width: 300px;
}

.delete-notif {
  cursor: pointer;
}

.notif-count {
  position: absolute;
  right: 5px;
}

.emoji-list {
  font-size: 23px;
  float: left;
  margin: -21px -23px -23px 12px;
  position: relative;
}

.emoji-details {
  font-size: 50px;
  margin: 0 auto -26px;
  position: relative;
  display: block;
}

.form-title.tabs span{
  font-size: 19px;
  padding: 5px 10px;
  border-radius: 20px;
  color: #443636;
}

.form-title.tabs span.active{
  color: #fff;
  background: #f1abab;
}

.active-setting {
  color: #d8aab8 !important;
  background: #fff;
  padding: 11px 3px 0;
  border-radius: 25px;
}
/* calendar styles */

.event-lashes, .event-lashes:focus {
  background: #6c757d !important;
}

.event-advancedtreatment {
  background: #ccb47c !important;
}

.event-facials {
  background: #bcb498 !important;
}

.event-bodytreatments {
  background: #dc92cf !important;
}

.event-filler {
  background: #ededed !important;
  color: #6b6767 !important;
}

.event-cancelled {
  background: #ff340a !important;
  background: linear-gradient(to right, #ff340a, #D8C67A) !important;
  background: -webkit-linear-gradient(to right, #ff340a, #D8C67A) !important;
}

.event-no-show {
  background: #ff340a !important;
  background: linear-gradient(to right, #16dd24, #D8C67A) !important;
  background: -webkit-linear-gradient(to right, #16dd24, #D8C67A) !important;
}



.event-pending {
  background: #50BDD3 !important;
  background: linear-gradient(to right, #50BDD3, #63ffa9) !important;
  background: -webkit-linear-gradient(to right, #50BDD3, #63ffa9) !important;

}

.apt-status-4 {
  color: red;
  text-decoration: line-through;
}

.apt-status-5 {
  text-decoration: line-through;
}

.apt-status-3 {
  color: #bbb;
}

.status-1 {
  display: none;
}


.status-2, .status-3, .status-4 {
  background: #ddd;
  margin-right: 5px;
  color: #000;
}

.location, .person {
  background: #f5cece;
  margin-right: 5px;
  color: #000;
}

.person {
  color: #3a8b92;
  background: #f4e8e8;
}

.location .material-icons, .person .material-icons{
  color: #9bb876;
  font-size: 20px;
}

.loc-gg1, .loc-gg2 {
  padding-top: 223px !important;
}

.loc-gg1 {
  background: url("assets/gg1.jpg") no-repeat top center;
}

.loc-gg2 {
  background: url("assets/gg2.jpg") no-repeat top center;
}

.client-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  top: 2px;
  margin-left: 4px;
}

.deposited, .member {
  background-position: 95% center !important;
  background-repeat: no-repeat !important;
}

.deposited {
  background-image: url('assets/money.png') !important;
}

.member {
  background-image: url('assets/members.png') !important;
}

.date-input {
  border: 1px solid #ddd;
}

.menu-dots {
  display: inline-flex;
  position: absolute;
  right: 71px;
}

.m-menu {
  position: absolute;
  top: 67px;
  width: 100%;
  background: #d8a9b8;
  left: 0;
}

.m-menu .link{
  display: block;
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid #d8c1c8;
  margin: 0 19px;
}

/* Campaigns */

.box-client {
  border: 1px solid #ddd;
  margin-top: 10px;
  max-height: 200px;
  overflow: scroll;
}

.box-customized {
  max-height: 300px;
}

.box-client .client-campaign {
  float: left;
  margin: 3px 4px;
  border: 1px solid #bbb;
  border-radius: 17px;
  padding: 0 6px 1px;
}

.custmize-btn {
  float: right;
  cursor: pointer;
  color: #8c7b7b !important;
  background: #efefef;
  padding: 5px;
  text-shadow: -1px 1px 0 #fff;
}

.modal-big {
  background: #ffffff;
  margin: 70px 30px 30px;
}

.custom-chip-delete {
  color: #bbb;
  float: right;
  font-size: 20px !important;
  position: relative;
  top: 2px;
  right: -1px;
  cursor: pointer;
}

.credit-member {
  position: absolute;
  right: 15px;
  top: 6px;
  color: #101010;
}

.credit-member .icon{
  font-size: 40px;
  position: relative;
  top: 6px;
}

.credit-member .credit{
  font-size: 37px;
}

.text-preview {
  border: 1px solid #ddd;
  padding: 5px;
}

/* modal */

.my_modal {
  top: 50%;
  left: 50%;
  margin-left: -300px;
  margin-top: -300px;
  transform: translate(-50%, -50);
  position: absolute;
  width: 600px;
  outline: none;
  background-color: #fff;
}

.done-modal img {
  max-width: 100%;
}

.cmapaign-details .text-preview{
  background: #f9f8f8;
  margin: 0 30px 0 0;
  border-radius: 6px;
  padding: 12px;
  max-height: none;
  overflow: auto;
}

.subscription {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d8a9b8+0,2989d8+100,207cca+100,ffc9ca+100&1+0,0+100 */
  background: -moz-linear-gradient(left,  rgba(216,169,184,1) 0%, rgba(255,201,202,0) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(216,169,184,1) 0%,rgba(255,201,202,0) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(216,169,184,1) 0%,rgba(255,201,202,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d8a9b8', endColorstr='#00ffc9ca',GradientType=1 ); /* IE6-9 */
}

#report-chart {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.add-note {
  padding: 1px 8px 1px 0;
  font-size: 12px;
  background: #ddd;
  display: none;
  border-radius: 15px;
  margin-left: 3px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.retail {

}

.retail .retail-box, .ld-comments .retail-box{
  border-left: 1px solid #bbbbbb;
  margin-left: 60px;
  border-bottom: 1px solid #bbbbbb;
  width: 140px;
  display: inline-block;
  height: 20px;
}

.ld-comments .retail-box {
  width: 31px;
  margin-left: 10px;
  height: 30px;
}


.sales {
  background: #dd3b67;
  display: inline-flex;
  color: #fff;
}

.add-note .sm-icon {
  font-size: 13px;
  margin: 2px 1px 2px 5px;
}

.note-text{
  display: block;
  width: 80%;
  padding: 5px;
  border: 1px solid #ddd;
  margin-top: 10px;
}

.single-apt:hover .add-note {
  display: inline-flex !important;
}

.note-details {
  background: #f8ffd4 url(https://drglowgetter.com/wp-content/uploads/2019/12/note.png) no-repeat 99% 30%;
  margin: -20px 5px 25px 200px;
  border: 1px solid #eee;
  padding: 3px 30px 3px 10px;
  border-radius: 6px;
  box-shadow: 0 3px 18px #e3e3e1;
}

.client-head > span {
  cursor: pointer;
}

.active-btn {
  background: #bbb !important;
}

.active-btn span{
  color: #fff !important;
}

.lead-log {
  padding: 5px 5px 5px 20px;
  border-left: 3px solid #bbb;
  margin-left: 10px;
}

.sales-container {
  border-left: 1px solid #bbbbbb;
  padding-left: 12px;
  margin: 0px 0 10px 220px;
  color: #381212;
  font-size: 13px;
}

.sale-item {

}

.tag-credit {
  position: relative;
  top: -5px;
}

.tag-returning {
  position: relative;
  top: -15px;
}

.income-user {
  cursor: pointer;
}

.income-user:hover {
  text-decoration: underline !important;
}

.size-15 {
  font-size: 15px;
}

.flag-icon {
  margin: -4px 5px -6px 0;
}

.lead-comment {
  background: #dff8e5;
  padding: 20px;
  border: 1px solid #d8d2d2;
  border-radius: 6px;
  box-shadow: 0 3px 18px #eeeee6;
  margin: -32px 5px 21px 41px;
}

.retail .lead-comment {
  padding: 9px;
  margin: -15px 5px 8px 200px;
}

.ld-dash .lead-comment{
  margin-top: 0;
  padding: 5px;
}

.comment-title .comment-icon, .lead-comment .comment-icon {
  font-size: 20px;
  color: #57e97e;
}

.lead-comment .comment-icon {
  color: #63ba7a
}

.note-icon {
  color: #30d455;
  cursor: pointer;
  position: relative;
  top: 7px;
  margin-right: 5px;
  left: 4px;
}

.former-m {
  padding: 5px;
  color: red;
  text-transform: uppercase;
  border: 3px solid red;
  font-weight: bold;
  opacity: 0.7;
  font-size: 13px;
  border-radius: 6px;
  transform: rotate(-11deg);
  position: relative;
  display: inline-block;
  top: 98px;
  left: 22%;
}

.button-filter .orange{
  background: lightgrey;
}

@media only screen and (max-width: 768px){
  .retail .retail-box{
    margin-left: 20px;
    width: 20px;
  }

  .sales-container{
    margin: 0 0 10px 54px;
  }

  .note-details{
    margin-left: 40px;
  }

  .retail .lead-comment {
    margin-left: 40px;
    margin-top: -21px;
  }
}

/*----------------- Mobile -------------------*/

@media only screen and (max-width: 425px){
  .my_modal {
    max-width: 98%;
    margin-left: -49%;
  }

  .form-title {
    font-size: 22px;
    margin-top: 4px;
  }

  .form-title.tabs span {
    font-size: 15px;
  }

  .notif-paper{
    right: 4px;
  }

  .box-customized {
    max-height: 79px;
  }

  .customize-modal .box-customized {
    max-height: 300px;
  }

  .text-preview {
    max-height: 57px;
    overflow: scroll;
  }

  .modal-big {
    margin: 15px;
  }

  .cmapaign-details .modal-big h4 {
    font-size: 15px;
  }

  .numbers-switch{
    margin: -50px 0 0 21px !important;
  }

  .location-sl {
    position: absolute;
    top: 66px;
    left: 81px;
  }

  .not-owner {
    left: 34px;
  }

  .mh-icons{
    display: inline-flex;
    position: absolute;
    top: -2px;
  }

  .menu-dots{
    right: 80px;
    top: 0
  }
   .not-owner-dots {
     right: 10px;
     top: -11px
   }

  .mh-sett {
    right: 46px;
  }

  .mh-notif {
    right: 10px
  }

  .active-setting {
    padding: 2px 3px 0;

  }
  .logo {
    width: 72px;
    margin-left: -51px;
    overflow: hidden;
  }
  .management{
    display: none;
  }

}
